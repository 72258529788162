@import "./bootstrap-override";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

[class^='container-'] {
  margin: 20px auto;
}

.container-98 {
  width: 98%;
}

.container-95 {
  width: 95%;
}

.container-90 {
  width: 90%;
}

.container-80 {
  width: 80%;
}

.container-70 {
  width: 70%;
}

.container-60 {
  width: 60%;
  margin: 30% auto;

  .no-data {
    color: $gray-400;
  }
}

/*Background Colors*/
.white-bg {
  background-color: $white;
}

.lightblue-bg {
  background-color: $cfa-lightblue;
}

.darkblue-bg {
  background-color: $cfa-darkblue;
}

.darkgray-bg {
  background-color: $cfa-gray;
}

.black-bg {
  background-color: $light-black;
}

.green-bg {
  background-color: $cfa-green;
}

.cfa-red-bg {
  background-color: $cfa-red;
}

.cfa-darkred-bg {
  background-color: $cfa-darkred;
}

.card-420{
  height: 420px;

  .card-body {
    height: 100%;
    overflow: hidden;
  }
}

.card-300{
  height: 300px;

  .card-body {
    height: 100%;
  }
}

.card-340{
  height: 340px;

  .card-body {
    height: 100%;
  }
}

/* Component Styles */
body {
  font-family: $apercu;
  color: $cfa-gray;
  background-color: $smokeshow;

  .section-col {
    // height: 650px;
    position: relative;
  }

  a {
    color: $cfa-gray;
  }

  .card,
  .btn,
  .alert {
    border-radius: 0;
  }

  .card {
    margin-bottom: 20px;
    background-color: $white;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .btn:hover>div {
    background-color: rgba(0, 0, 0, 0.1);
  }

  h5.text-danger {
    margin-bottom: 10px;
  }

  .alert {
    margin-bottom: 0;
  }

  .alert-container {
    margin-bottom: 1rem;
  }

  .alert-success {
    background-color: $cfa-green;
    color: $white;
  }

  .alert-danger {
    background-color: $cfa-red;
    color: $white;
  }

  .warnings {
    box-shadow: 0 0 5px #FFB549;
    border: 1px solid #FFB549;
  }


  .fails {
    box-shadow: 0 0 5px $cfa-red;
    border: 1px solid $cfa-red;
  }

  .validation-check {
    padding: 3% 0;
  }

  .validation-check i {
    text-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.22);
  }

  .btn:disabled{
    background-color: $cfa-gray;
    border-color: $cfa-gray;
    opacity: 0.3;
  }

  .btn-spinner {
    width: 71px;
  }

  #chart text.c3-chart-arcs-title {
    fill: #868e96;
    font-size: 2em;
  }

  .error{
    position: relative !important;
    width: 100% !important;
    text-align: center;
    font-size: small;
    // z-index: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

}

// .cfaCustomLightTheme {
//   background-color: white !important;
//   margin-bottom: $spacer;
//   -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
//   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
// }
