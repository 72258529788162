@import "../../theme/theme-variables.scss";

.adjustment-modal{
  .modal-dialog{
    max-width: 600px;
    .modal-content{
      border-radius: 4px;
    }
  }
}
