@import "../../theme/theme-variables.scss";

.cfa-success-message-modal{
  .modal-dialog{
    max-width: 350px;
    .modal-content{
      border-radius: 10px;
    }
  }
}
