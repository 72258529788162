/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss";
@import "~@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss";

// Theme customization
@import "theme/theme";
@import "./app/cfa-adjustment-approval/cfa-adjustment-approval.component.global";
@import "./app/cfa-success-message-modal/cfa-success-message-modal.component.global";
@import "./app/licensee-monthly/licensee-monthly.component.global";


.detail-popover{
  --bs-popover-bg: #f2f8fa;
  --bs-popover-border-color: #7db4cb;
  --bs-popover-border-width: 2px;
  --bs-popover-max-width: 600px;
  --bs-popover-header-padding-x: 0.5rem;
  --bs-popover-header-padding-y: 0.5rem;
  width: 500px;
  .popover-arrow {
    display: none;
  }
  .closeButton{
    border: none;
    background: none;
    color: #5c6770;
    position: absolute;
    right: 0.5rem;
    top: 0.8rem;
    padding: 0px 10px;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  ul{
    margin-top: 25px;
    li{
      margin-bottom: 20px;
    }
  }
}
