/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free-webfonts/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss


// CFA Fonts
@font-face {
  font-family: CaeciliaLight;
  src: url('/assets/fonts/CaeciliaCom-45Light.ttf');
}

@font-face {
  font-family: CaeciliaBold;
  src: url('/assets/fonts/CaeciliaCom-75Bold.ttf');
}

@font-face {
  font-family: CaeciliaHeavy;
  src: url('/assets/fonts/CaeciliaCom-85Heavy.ttf');
}

@font-face {
  font-family: Apercu;
  src: url('/assets/fonts/apercu-regular.otf');
}


$apercu: 'Apercu';
$caecilia-light: 'CaeciliaLight';
$caecilia-bold: 'CaeciliaBold';
$caecilia-heavy: 'CaeciliaHeavy';

// Define your app variables and framework variables overrides here

// CFA Colors
$cfa-red: #DD0031;
$cfa-darkred: #AF272F;
$cfa-green: #249E6B;
$cfa-darkblue: #004F71;
$cfa-lightblue: #3EB1C8;
$cfa-gray: #5d666f;

$smokeshow: #F5F5F5;
$light-black: #262626;
//
// Color system
//

$white:  #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;

$blue:    #0073dd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$theme-colors: (
  primary: $cfa-darkblue,
  secondary: $gray-600,
  success: $cfa-green,
  info: $cfa-lightblue,
  warning: $yellow,
  danger: $cfa-red,
  light: $gray-100,
  dark: $cfa-gray
);

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
