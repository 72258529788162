@import "../../theme/theme-variables";
.warning-75k-popover{
  background: yellow;
  border: 0px;
  .popover-body{
    padding: 0px;
  }
  .popover-arrow{
    display: none;
  }
}
