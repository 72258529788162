//.text-danger{
//  background: yellow;
//}
.card-body{
  padding: 1.25rem;
}

.form-control-sm.form-select{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 1.35rem;
  font-size: .875rem;
  line-height: 1.5;
  word-spacing: 1rem;
  border-radius: 0.2rem;
}

.small {
  font-size: 80%;
  font-weight: 400;
}
body{
  --bs-body-color: #5d666f;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
}

.card-body{
  color: var(--bs-body-color);
}

.table{
  --bs-table-color: #212529;
}
.navbar{
--bs-navbar-color: #5D666F;
}
.status-table .table td{
  border-bottom-width: 0px;
}

.table thead th{
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.form-inline{
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  .form-control{
    width: auto;
    display: inline-block;
  }
}
.float-right{
  float: right !important;
}

.form-select{
  background-position: right 0.25rem center;
  background-size: 9px 12px;
}
